<template>
   <div class="desktopLayoutClass" style="background-color: #f5faff;z-index: 0;height: 100vh" >
      <v-app style="background-color: #f5faff;z-index: 0;">
         <div
         class="header"
         style="
         display: flex;
         flex-direction: row;
         padding-top: 10px;
         padding-left: 5px;
         "
         >
         <div style="display: flex; flex-direction: row; width: 100%">
          <div
          style="width: 10%; padding-top: 6px"
          align="left"
          v-on:click="redirectToHomePage()"
          >
          <img
          src="https://img.icons8.com/material-outlined/20/000000/left.png"
          />
       </div>
       <div style="width: 90%" align="left">
         <span style="font-weight: 600; font-size: 15pt"
         >Video Consultation</span
         >
      </div>
   </div>
</div>
<!-- Dialog Start -->
<div>
   <v-row justify="center">
      <v-dialog v-model="auth_error_enable" persistent max-width="290">
         <v-card>
            <v-card-title style="word-break:normal">
                 {{$t("Warning_Message.Unauthorized")}}
            </v-card-title>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="green darken-1" text @click="closeDialogUnauthorized" style="background-color: #1467BF;">
                  {{$t("Warning_Message.Ok")}}
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-row>
</div>


<div>
   <v-row justify="center">
      <v-dialog v-model="appointmentTimeOverModal" persistent max-width="290">
         <v-card>
            <v-card-title style="word-break:normal">
              {{$t("Warning_Message.Teleconsultation_Time_Is_Up")}}
            </v-card-title>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="green darken-1" text @click="closeDialogAppointmentTimeOverModal" style="background-color: #1467BF;">
                   {{$t("Warning_Message.Ok")}}
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-row>
</div>

<div>
   <v-row justify="center">
      <v-dialog v-model="appointmentStartMessageModal" persistent max-width="290">
         <v-card>
            <v-card-title style="word-break:normal">
                {{$t("Warning_Message.Please_Join5Minutes_Before_Appointment_Time")}}
            </v-card-title>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="green darken-1" text @click="closeDialogAppointmentStartMessageModal" style="background-color: #1467BF;">
                  {{$t("Warning_Message.Ok")}}
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-row>
</div>

<div>
   <v-row justify="center">
      <v-dialog v-model="error_enable" persistent max-width="290">
         <v-card>
            <v-card-title style="word-break:normal">
               {{$t("Warning_Message.Something_Went_Wrong")}}
            </v-card-title>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="green darken-1" text @click="closeDialogError" style="background-color: #1467BF;">
                  {{$t("Warning_Message.Ok")}}
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-row>
</div>
<!-- Dialog Close -->
<div v-if="executeSecondTimer">
 <p style="color:red;border:2px;" class="timerClass">{{countDownTimer}}</p>
 <v-row>
   <v-col  cols="12"
   sm="12" style="padding: 0px;">
   <!-- <iframe
   :src="appointment_url"
   allow="camera; microphone; fullscreen; speaker; display-capture" style="overflow:hidden;height:90vh;width:100%"
   ></iframe> -->
</v-col>
</v-row>
</div>
<div v-else>
   <div>
      <v-row>
         <div class="timerClass" style="margin-left: 50%;">
            <p style="border:2px; background-color: red; color: white; padding: 5px;">{{countDownTimer}}</p>
         </div>
      </v-row>
      <v-row>
      <v-col  cols="12"
      sm="12" style="padding: 0px;">
      <!-- <iframe
      :src="appointment_url"
      allow="camera; microphone; fullscreen; speaker; display-capture" style="overflow:hidden;height:90vh;width:100%"
      ></iframe> -->
       <whereby-embed :room="appointment_url"  audio=on video=on />
         </v-col>
      </v-row>
   </div>
   <!-- <div v-else>
      <v-progress-circular
         indeterminate
         color="primary"
      ></v-progress-circular>
   </div> -->
</div>
</v-app>
</div>
</template>

<script>
import axios from "axios";
import NavBar from '../../../views/navbar.vue';
export default {
 name: 'CustomerVideoCall',
 components: {
   NavBar
},
data() {
   return {
      auth_error_enable: false,
      error_enable: false,
      // booking_id:"",
      currentCustomer : "",
      customer_name :"",
      customer_id : "",
      appointment_id : "",
      appointment_url : "",
      countDownTimer : "",
      timerValue : "",
      appointmentObject : "",
      clearDistance : false,
      executeSecondTimer: false,
      isMobile : false,
      appointmentTimeOverModal : false,
      showTimer : true,
      appointmentStartMessageModal : false,
      isLoading: true

   }
},
methods: {
   logEvent(event){
      // console.log("Meeting Left");
      if(event.type === "leave")
      {
         var leaveLogEventBody={

              bookingId : this.appointment_id,
              userType: "CUSTOMER",
              token : this.currentCustomer,
              typeOfUser : "CUSTOMER",
         }
         // console.log("[leaveLogEventBody]",leaveLogEventBody);
              axios.post(process.env.VUE_APP_BACKEND_URL + "/exitCallLogEntry",leaveLogEventBody)
          .then((exitCallLogEntryResponse)=>{
             // console.log("[Exit Call Event Response]");
          })
          .catch((exitCallLogEntryError)=>{
             console.log("[Exit Call Event error]",exitCallLogEntryError);

        });
        this.$router.push({
            path: '/customer/feedback/' + this.appointment_id
         });

      }
   },
   joinLogEvent(event){
      // console.log("Meeting Joined", this.appointment_id);
      if(event.type === "join")
      {
         var JoinLogEventBody={

             bookingId : this.appointment_id,
              userType: "CUSTOMER",
              token : this.currentCustomer,
              typeOfUser : "CUSTOMER",
         }
         // console.log("[Join Log event]",JoinLogEventBody);
              axios.post(process.env.VUE_APP_BACKEND_URL + "/joinCallEntry",JoinLogEventBody)
          .then((joinCallEntryResponse)=>{
             // console.log("[Join Call Event Response]",joinCallEntryResponse);
          })
          .catch((joinCallEntryError)=>{
             console.log("[Join Call Event Error]",joinCallEntryError);

        });

      }
   },

   sleep(booking_id,token,router){
       setTimeout(function(){
               var changeAppointmentStatusOnCompletiondata={
              bookingId : this.appointment_id,
              token : token,
              typeOfUser : "CUSTOMER"
         }
         // console.log("data :",changeAppointmentStatusOnCompletiondata);
             axios.post(process.env.VUE_APP_BACKEND_URL + "/changeAppointmentStatusOnCompletion",changeAppointmentStatusOnCompletiondata)
         .then((changeAppointmentStatusOnCompletionSuccess)=>{
              router.push({
                    path:'/customer/feedback/' + booking_id
                })
         })
         .catch((changeAppointmentStatusOnCompletionError)=>{
               router.push({
                    path:'/customer/feedback/' + booking_id
                })
         });
       },5000);
    },
   redirectToHomePage() {
      this.$router.go(-1);
   },
   closeDialogAppointmentStartMessageModal(){
     this.$router.push({
        name: "CustomerHomeScreen",
     });
  },
  closeDialogAppointmentTimeOverModal(){
     this.appointmentTimeOverModal = false;
     this.$router.push({
        path : "/customer/feedback/"+this.appointment_id
     });
  },
  closeDialogUnauthorized(){
    this.auth_error_enable = false;
    this.$router.push({
     name: "Login",
  });
 },
 closeDialogError(){
    this.error_enable = false;
 },
  timerOne(){
   var startTime = new Date(this.appointmentObject.booking_epoch_time).getTime();
   var currentTime = new Date().getTime();
   // console.log(startTime, currentTime);
   console.log("in timerone");
   if(startTime - currentTime > 600000){
      this.showTimer = true;
      // this.countDownTimer = "";
   }
   if(this.showTimer){
      var countDownDate = new Date(this.appointmentObject.booking_closing_epoch_time).getTime() ;
      //date_of_appointment + " " +this.appointmentObject.time_slot.split("-")[1]+":00 GMT+0630"
      var intervalOne = setInterval(() =>{
         var  today= new Date().getTime();
         var distance = countDownDate - today;
         var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
         var seconds = Math.floor((distance % (1000 * 60)) / 1000);
         this.countDownTimer = minutes+":"+seconds;
         console.log(this.countDownTimer);
         if(distance < 0){
            clearInterval(intervalOne);
            this.countDownTimer = "00:00";
            this.appointmentTimeOverModal = true;
            this.sleep(this.appointment_id,this.currentCustomer,this.$router);
         // this.timerTwo();
      }
   },1000);
   }
   else{
      this.appointmentStartMessageModal = true;
   }
},

timerTwo(){
   // this.executeSecondTimer = true;
   var endTime = this.appointmentObject.time_slot.split("-")[1]
   var bufferTime = Number(endTime.split(":")[1]) + 3;
   var combinedTime = endTime.split(":")[0] + ":" + bufferTime
   var countDownDate = new Date(this.appointmentObject.date_of_appointment + " " +String(combinedTime)+":00 GMT+0630").getTime() ;
   //console.log("bufferTime ",bufferTime)
   // console.log("countDownDate ",countDownDate)
   // console.log("Inside second timer");
   var  today= new Date().getTime();
   var distance = countDownDate - today;
   var intervalTwo = setInterval(() =>{
      // console.log("distance : ",distance);
      distance = distance - 1000;
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.countDownTimer = minutes+":"+seconds;
      if(distance < 0){
         clearInterval(intervalTwo);
         this.countDownTimer = "00:00";
         this.appointmentTimeOverModal = true;
         this.executeSecondTimer = false;
      }
   },1000);
}

},

mounted(){
   this.isMobile = this.$device.mobile;
   if(this.$store.state.locale == "en")
      document.title = "Video Consultation"
    else
      document.title = "ဗီဒီယိုဖြင့်တိုင်ပင်ဆွေးနွေးခြင်း"

   this.executeSecondTimer = false;
   this.currentCustomer = this.$cookies.get("customerToken");
   var access = this.$cookies.get("access");
   if(!this.currentCustomer){
      this.$router.push({
        name : "Login"
     })
   }
   else{
      this.appointment_id = this.$route.params.booking_id
      // console.log(this.appointment_id);
      var viewParticularAppointmentPageData = {
        "token" : this.currentCustomer,
        "bookingId" : this.appointment_id,
        "typeOfUser" : "CUSTOMER"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/videoCallPage", viewParticularAppointmentPageData)
      .then((viewParticularAppointmentPageResponse) => {
         this.customer_id = viewParticularAppointmentPageResponse.data.customer._id;
         this.customer_name = viewParticularAppointmentPageResponse.data.customer.customer_name;
         this.appointmentObject = viewParticularAppointmentPageResponse.data.data;
         this.appointment_url = viewParticularAppointmentPageResponse.data.data.meeting_link
         this.timerOne();
         this.isLoading = false;
         // console.log(viewParticularAppointmentPageResponse);
         var scriptEle = document.querySelector('whereby-embed');
         // console.log(scriptEle);
         scriptEle.addEventListener("join", this.joinLogEvent);
         // console.log("[Join call Screlement]",scriptEle);
         scriptEle.addEventListener("leave", this.logEvent);
         // console.log("[Leave call Screlement]",scriptEle);

         // User config call removed.
         // var user_config_body ={
         //    appointment_reference_number : this.appointmentObject.videoCallPage,
         //    appointment_id : this.appointmentObject._id,
         //    meeting_link : this.appointmentObject.meeting_link,
         //    config_type : "VIDEO_CALL",
         //    user_type: "CUSTOMER",
         //    userId : this.customer_id,
         //    user_name : this.customer_name
         // }
         // axios.post(process.env.VUE_APP_BACKEND_URL+"/createUserConfig",user_config_body)
         // .then((createUserConfigResponse) =>{
         //    // console.log(createUserConfigResponse);
         // })
         // .catch((createuserconfigError) =>{
         //    console.log(createuserconfigError);
         // });
         
      })
      .catch((viewParticularAppointmentPageError)=>{
        console.log(viewParticularAppointmentPageError)
        if(viewParticularAppointmentPageError.response.status == 401){
          this.auth_error_enable = true;
        }
        else{
          this.error_enable = true;
        }
      });

   }
}
}
</script>

<style scoped>
.timerClass{
   position: absolute;
   margin-left : 30px;
   margin-top: 10px;
   font-size:150%;

}
whereby-embed{
   overflow:hidden;height:90vh;width:100%
}
/* @media (min-width: 800px){
  .videoCallClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }} */
</style>